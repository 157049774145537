import * as Sentry from '@sentry/react';

const slugDiv = document.querySelector('#slug-attributes');
const shouldInit = slugDiv.getAttribute('data-errors') === 'true';
const environment = slugDiv.getAttribute('data-env');

const enabled = shouldInit && environment !== 'development';

const sentryPublic = slugDiv.getAttribute('data-sentry-public');
const sentryId = slugDiv.getAttribute('data-sentry-id');
const dsn = `https://${sentryPublic}@o333066.ingest.sentry.io/${sentryId}`;

Sentry.init({
  beforeSend: (event) => {
    // only log errors that have a component tag
    if (event.tags?.component) {
      return event;
    }

    return null;
  },
  debug: environment === 'development',
  dsn,
  enabled, // comment this out to see errors in development
  environment,
  integrations: (integrations) => {
    const REMOVED_INTEGRATIONS = ['GlobalHandlers', 'TryCatch'];

    return integrations.filter(integration => !REMOVED_INTEGRATIONS.includes(integration.name));
  },
  maxBreadcrumbs: 100,
  tracesSampleRate: 0,
});
